<template>
  <div class="" v-loading="loading">
    <div>
      <el-form
        class="column3"
        label-width="11em"
        label-position="top"
        :model="projectInformation"
        ref="form"
      >
        <el-card>
          <el-descriptions
            class="margin-top"
            title="项目基本信息"
            direction="vertical"
            :column="7"
            border
          >
            <el-descriptions-item :span="2" label="项目名称">
              <el-form-item
                label-width="0px"
                prop="projectName"
                ref="projectName"
                style="width: 100%"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.projectName"
                  placeholder="请输入项目名称"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="项目经理">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="managerName"
                ref="managerName"
              >
                <el-input
                  v-if="disabled"
                  :disabled="disabled"
                  size="small"
                  v-model="projectInformation.managerName"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="组长">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="leaderManagerName"
                ref="leaderManagerName"
              >
                <el-input
                  v-if="disabled"
                  :disabled="disabled"
                  size="small"
                  v-model="projectInformation.leaderManagerName"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="部门主管经理">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="deptCompetentManagerId"
                ref="deptCompetentManagerId"
              >
                <el-input
                  v-if="disabled"
                  :disabled="disabled"
                  v-model="projectInformation.competentManagerName"
                  placeholder="请输入部门主管经理"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="分管高管">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="deptFzManagerId"
                ref="deptFzManagerId"
              >
                <el-input
                  v-if="disabled"
                  :disabled="disabled"
                  v-model="projectInformation.fzManagerName"
                  placeholder="请输入分管高管"
                ></el-input
              ></el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="业主联系人">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="customersName"
                ref="customersName"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.customersName"
                  placeholder="请输入业主联系人"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="业主单位">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="customersUnit"
                ref="customersUnit"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.customersUnit"
                  placeholder="请输入业主单位"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="项目承接公司">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="companyType"
                ref="companyType"
              >
                <Dictionary
                  :disabled="disabled"
                  v-model="projectInformation.companyType"
                  code="COMPANY_TYPE"
                  placeholder="请选择项目承接公司"
                />
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="实施地点" :span="2">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="implementSite"
                ref="implementSite"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.implementSite"
                  placeholder="请输入实施地点"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="预计开始日期">
              <el-form-item
                style="width: 100%"
                label-width="0px"
                prop="projectPredictStartDate"
                ref="projectPredictStartDate"
              >
                <el-date-picker
                  :disabled="disabled"
                  value-format="timestamp"
                  v-model="projectInformation.projectPredictStartDate"
                  type="date"
                  placeholder="请选择预计开始日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="预计完成日期">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="projectPredictEndDate"
                ref="projectPredictEndDate"
              >
                <el-date-picker
                  :disabled="disabled"
                  value-format="timestamp"
                  v-model="projectInformation.projectPredictEndDate"
                  type="date"
                  placeholder="请选择预计完成日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="实际开始日期">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="actualPredictStartDate"
                ref="actualPredictStartDate"
              >
                <el-date-picker
                  :disabled="disabled"
                  value-format="timestamp"
                  v-model="projectInformation.actualPredictStartDate"
                  type="date"
                  placeholder="请选择实际开始日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="实际完成日期">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="accomplishDate"
                ref="accomplishDate"
              >
                <el-date-picker
                  :disabled="disabled"
                  value-format="timestamp"
                  v-model="projectInformation.accomplishDate"
                  type="date"
                  placeholder="请选择实际完成日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="要求完成日期">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="requireAccomplishDate"
                ref="requireAccomplishDate"
              >
                <el-date-picker
                  :disabled="disabled"
                  value-format="timestamp"
                  v-model="projectInformation.requireAccomplishDate"
                  type="date"
                  placeholder="请选择要求完成日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>

            <template v-if="projectInformation.id">
              <el-descriptions-item label="合同名称">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="agreementName"
                  ref="agreementName"
                >
                  <el-input
                    :disabled="true"
                    v-model="projectInformation.agreementName"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>

            <el-descriptions-item label="剩余工作量比例(%)">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="residualWorkRatio"
                ref="residualWorkRatio"
                >{{ projectInformation.residualWorkRatio }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="剩余工作量说明" :span="projectInformation.id ? 3 : 4">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="residualWorkRatioContent"
                ref="residualWorkRatioContent"
              >
                <el-input
                  :disabled="disabled"
                  autosize
                  type="textarea"
                  v-model="projectInformation.residualWorkRatioContent"
                  placeholder="请输入剩余工作量说明"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="备注">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="residualWorkRatioContent"
                ref="residualWorkRatioContent"
              >
                <el-input
                  :disabled="disabled"
                  autosize
                  type="textarea"
                  v-model="projectInformation.remark"
                  placeholder="请输入项目基本信息备注"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
        <div class="el-form-item" style="width: 100%; margin-top: 20px">
          <div class="el-form-item__content">
            <table class="table">
              <tr>
                <th style="width: 50px">序号</th>
                <th style="width: 150px">部门名称</th>
                <th style="width: 150px">部门类型</th>
                <th style="width: 150px">预算(元)</th>
                <!-- <th style="width: 130px">项目经理</th> -->
                <th style="width: 150px">任务占比(%)</th>
                <th>任务分工说明</th>
              </tr>
              <template v-for="(item, index) in projectInformation.projectDeptList">
                <tr :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.deptId | cascader(deptData, 'id', 'deptName') }}
                  </td>
                  <td>
                    <Dictionary
                      :disabled="disabled"
                      size="small"
                      v-model="item.type"
                      code="Project_Dept"
                      placeholder="请选择"
                    />
                  </td>
                  <td>
                    {{ item.predictAmount }}
                  </td>
                  <td>
                    {{ item.taskRatio }}
                  </td>
                  <td>
                    {{ item.taskExplain }}
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <el-card>
          <el-descriptions
            class="margin-top"
            title="业务类型"
            style="width: 100%"
            direction="vertical"
            :column="5"
            border
          >
            <el-descriptions-item label="商务类型">
              <el-form-item
                style="width: 100%"
                label-width="0px"
                prop="businessType"
                ref="businessType"
              >
                <Dictionary
                  :disabled="disabled"
                  v-model="projectInformation.businessType"
                  code="BUSINESS_TYPE"
                  placeholder="请选择"
                />
              </el-form-item>
            </el-descriptions-item>
            <template v-if="projectInformation.id">
              <el-descriptions-item label="项目状态">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="projectStatus"
                  ref="projectStatus"
                >
                  <Dictionary
                    :disabled="disabled"
                    v-model="projectInformation.projectStatus"
                    code="PROJECT_NEW_STATUS"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="项目状态修改时间">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="projectStatusUpdateTime"
                  ref="projectStatusUpdateTime"
                >
                  <el-date-picker
                    disabled
                    value-format="timestamp"
                    v-model="projectInformation.projectStatusUpdateTime"
                    type="date"
                    :clearable="false"
                    placeholder="请选择项目状态修改时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="项目审批状态">
                <el-form-item label-width="0px" style="width: 100%" prop="status" ref="status">
                  <Dictionary
                    :disabled="true"
                    v-model="projectInformation.status"
                    code="PROJECT_STATUS"
                  />
                </el-form-item>
              </el-descriptions-item>
            </template>
            <el-descriptions-item>
              <template slot="label"> 项目分类 </template>
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="projectCategory"
                ref="projectCategory"
              >
                <Dictionary
                  :disabled="disabled"
                  v-model="projectInformation.projectCategory"
                  code="PROJECT_CATEGORY"
                  placeholder="请选择项目分类"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="项目来源">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="projectSource"
                ref="projectSource"
              >
                <Dictionary
                  :disabled="disabled"
                  v-model="projectInformation.projectSource"
                  code="PROJECT_SOURCE"
                  placeholder="请选择项目来源"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="合作费比例(%)"
              v-if="projectInformation.projectSource == 'zi_zhi_he_zuo'"
            >
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="collaborationCostsRatio"
                ref="collaborationCostsRatio"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.collaborationCostsRatio"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="业务种类">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="businessLines"
                ref="businessLines"
              >
                <Dictionary
                  :disabled="disabled"
                  v-model="projectInformation.businessLines"
                  code="BUSINESS_LINES"
                  placeholder="请选择业务类型"
                />
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="项目种类">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="projectLines"
                ref="projectLines"
              >
                <Dictionary
                  style="width: 100%"
                  :disabled="disabled"
                  v-model="projectInformation.projectLines"
                  :code="projectInformation.businessLines"
                  placeholder="请选择项目种类"
                />
              </el-form-item>
            </el-descriptions-item>
            <template v-if="projectInformation.id">
              <el-descriptions-item label="项目绩效状态">
                <el-form-item
                  label-width="0px"
                  style="width: 200px"
                  prop="performanceStatus"
                  ref="performanceStatus"
                >
                  <Dictionary
                    :disabled="true"
                    v-model="projectInformation.performanceStatus"
                    code="PROJECT_PERFORMANCE_STATUS"
                  />
                </el-form-item>
              </el-descriptions-item>
            </template>
          </el-descriptions>
        </el-card>
        <el-card>
          <el-descriptions
            class="margin-top"
            title="项目金额"
            direction="vertical"
            :column="5"
            style="width: 100%"
            border
          >
            <el-descriptions-item label="我方合同金额(元)">
              <el-form-item
                label-width="0px"
                prop="predictContractAmount"
                ref="predictContractAmount"
                style="width: 100%"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.predictContractAmount"
                ></el-input>
                <span style="color: red">{{ AmountInWords.predictContractAmount }}</span>
              </el-form-item>
            </el-descriptions-item>

            <template v-if="projectInformation.id">
              <el-descriptions-item label="实际合同金额(元)">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="actualContractAmount"
                  ref="actualContractAmount"
                >
                  <el-input
                    :disabled="true"
                    v-model="projectInformation.actualContractAmount"
                    placeholder="请输入"
                  ></el-input>
                  <span style="color: red">{{ AmountInWords.actualContractAmount }}</span>
                </el-form-item>
              </el-descriptions-item>
            </template>

            <!-- 移除项目外部商务费用 -->
            <!-- <el-descriptions-item label="预计外部商务费用(元)">
              <el-form-item
                label-width="0px"
                style="width:100%"
                prop="predictExteriorBusinessCosts"
                ref="predictExteriorBusinessCosts"
              >
                <el-input
                  :disabled="disabled"
                  v-model="projectInformation.predictExteriorBusinessCosts"
                ></el-input>
                <span style="color: red">{{ AmountInWords.predictExteriorBusinessCosts }}</span>
              </el-form-item>
            </el-descriptions-item> -->

            <template v-if="projectInformation.id">
              <!-- 移除项目外部商务费用 -->
              <!-- <el-descriptions-item label="外部商务费用(元)">
                <el-form-item
                  label-width="0px"
                  style="width:100%"
                  prop="exteriorBusinessCosts"
                  ref="exteriorBusinessCosts"
                >
                  <el-input
                    :disabled="disabled"
                    v-model="projectInformation.exteriorBusinessCosts"
                  ></el-input>
                  <span style="color: red">{{ AmountInWords.exteriorBusinessCosts }}</span>
                </el-form-item>
              </el-descriptions-item> -->

              <!-- <el-descriptions-item label="实际可支配费用(元)">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="actualDisposableProjectExpense"
                  ref="actualDisposableProjectExpense">
                  <el-input
                    :disabled="disabled"
                    v-model="projectInformation.actualDisposableProjectExpense"></el-input>
                  <span style="color: red">{{ AmountInWords.actualDisposableProjectExpense }}</span>
                </el-form-item>
              </el-descriptions-item> -->

              <el-descriptions-item label="项目实际金额(元)">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="actualAmount"
                  ref="actualAmount"
                >
                  <el-input
                    :disabled="disabled"
                    v-model="projectInformation.actualAmount"
                    placeholder="请输入项目实际金额"
                  ></el-input>
                  <span style="color: red">{{ AmountInWords.actualAmount }}</span>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="招标费(元)">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="tenderFee"
                  ref="tenderFee"
                >
                  <el-input :disabled="disabled" v-model="projectInformation.tenderFee"></el-input>
                  <span style="color: red">{{ AmountInWords.tenderFee }}</span>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item label="设备折旧费">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="depreciationEquipmentFee"
                  ref="depreciationEquipmentFee"
                >
                  <el-input
                    :disabled="disabled"
                    v-model="projectInformation.depreciationEquipmentFee"
                  ></el-input>
                  <span style="color: red">{{ AmountInWords.depreciationEquipmentFee }}</span>
                </el-form-item>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="项目补贴">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="projectSubsidy"
                  ref="projectSubsidy"
                >
                  <el-input
                    :disabled="disabled"
                    v-model="projectInformation.projectSubsidy"
                  ></el-input>
                  <span style="color: red">{{ AmountInWords.projectSubsidy }}</span>
                </el-form-item>
              </el-descriptions-item> -->
            </template>
          </el-descriptions>
        </el-card>
        <el-card>
          <el-descriptions
            direction="vertical"
            style="width: 100%"
            :column="6"
            border
            title="税费"
            class="margin-top"
          >
            <el-descriptions-item label="增值税类型" :span="3">
              <template slot="label">
                增值税类型
                <el-button
                  size="mini"
                  type="warning"
                  style="margin-left: 10%; padding: 5px"
                  @click="vatTypesShow = true"
                  >增值税类型说明</el-button
                >
              </template>
              <el-form-item label-width="0px" prop="vatType" ref="vatType" style="width: 100%">
                <el-select
                  v-model="projectInformation.vatType"
                  :disabled="disabled"
                  placeholder="请选择增值税类型"
                  class="ipt_width"
                >
                  <el-option
                    v-for="item in zzsList"
                    :key="item.id"
                    :label="item.vatName"
                    :value="item.vatType"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="增值税类型详细">
              <el-form-item prop="vatData" ref="vatData" style="width: 100%">
                <VatDictionary
                  style="width: 100%"
                  valType="Data"
                  :disabled="disabled"
                  v-model="projectInformation.vatData"
                  :clearable="false"
                  :code="projectInformation.vatType"
                  placeholder="请选择增值税类型详细"
                />
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="税费(元)">
              <el-form-item
                label-width="0px"
                style="width: 100%; height: 58px"
                prop="taxesFee"
                ref="taxesFee"
              >
                <div class="Taxes father">{{ SJ_taxes | thousands }}</div>
                <div style="color: red" class="son">{{ AmountInWords.taxesFee }}</div>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions direction="vertical" :column="9" style="width: 100%" border>
            <el-descriptions-item label="增值税税率(%)" labelClassName="taxLabelClassName">
              <el-form-item label-width="0px" style="width: 100%" prop="vatRate" ref="vatRate">
                <!-- <span class="Taxes">{{ projectInformation.vatRate }}%</span> -->
                <VatDictionary
                  style="width: 100%"
                  valType="TaxRate"
                  :disabled="disabled"
                  v-model="projectInformation.vatRate"
                  :code="projectInformation.vatData"
                  :clearable="false"
                  placeholder="请选择增值税税率"
                />
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="附加税税率(%)" labelClassName="taxLabelClassName">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="additionalTaxRate"
                ref="additionalTaxRate"
              >
                <span class="Taxes">12</span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="印花税税率(%)" labelClassName="taxLabelClassName">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="stampTaxRate"
                ref="stampTaxRate"
              >
                <span class="Taxes">0.03</span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="增值税(元)" labelClassName="vatLabelClassName">
              <el-form-item label-width="0px" style="width: 100%" prop="actualVat" ref="actualVat">
                <!-- <span class="Taxes">{{ Sj_configcomp | thousands }}</span> -->
                <el-input
                  :disabled="disabled"
                  @focus="focusAmount('actualVat')"
                  @blur="blurAmount('actualVat')"
                  v-model="projectInformation.actualVat"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="附加税(元)" labelClassName="vatLabelClassName">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="actualAdditionalTax"
                ref="actualAdditionalTax"
              >
                <!-- <span class="Taxes">{{
                        Sj_actualAdditionalTax | thousands
                      }}</span> -->
                <el-input
                  :disabled="disabled"
                  @focus="focusAmount('actualAdditionalTax')"
                  @blur="blurAmount('actualAdditionalTax')"
                  v-model="projectInformation.actualAdditionalTax"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="印花税(元)" labelClassName="vatLabelClassName">
              <el-form-item
                label-width="0px"
                style="width: 100%"
                prop="actualStampTax"
                ref="actualStampTax"
              >
                <!-- <span class="Taxes">{{
                        Sj_actualStampTax | thousands
                      }}</span> -->
                <el-input
                  :disabled="disabled"
                  @focus="focusAmount('actualStampTax')"
                  @blur="blurAmount('actualStampTax')"
                  v-model="projectInformation.actualStampTax"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>

        <el-card v-if="projectInformation.id">
          <div slot="header">
            <span style="font-weight: 900; color: black">预估成本方案</span>
          </div>
          <div style="width: 100%; flex: 1">
            <CostPlan style="width: 100%" ref="costPlan" :disabled="true" />
          </div>
        </el-card>

        <el-card v-if="projectInformation.id">
          <div slot="header">
            <span style="font-weight: 900; color: black">会议信息</span>
          </div>
          <el-form-item
            label="项目立项会议纪要"
            prop="projectApprovalMeetingSummary"
            ref="projectApprovalMeetingSummary"
            style="width: 100%"
          >
            <el-input
              readonly
              v-model="projectInformation.projectApprovalMeetingSummary"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-card>

        <div class="el-form-item" style="width: 100%">
          <label class="el-form-item__label" style="font-weight: 900; color: black; font-size: 16px"
            >外协信息</label
          >
          <div class="el-form-item__content">
            <el-table :data="projectInformation.projectOutsourceList" border style="width: 100%">
              <el-table-column align="center" type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column prop="outsourceName" label="外协公司" align="center" min-width="180">
              </el-table-column>
              <el-table-column prop="content" label="约定内容" align="center" min-width="220">
              </el-table-column>
              <el-table-column prop="memberQuantity" label="外协人数" align="center" width="110">
              </el-table-column>
              <el-table-column prop="outsourceCost" label="外协费用(元)" align="center" width="130">
              </el-table-column>
              <el-table-column prop="dockingPeople" label="对接人" align="center" width="100">
              </el-table-column>
              <el-table-column prop="phone" label="对接人电话" align="center" width="110">
              </el-table-column>
              <el-table-column
                prop="projectOutsourceStartDate"
                label="开始时间"
                align="center"
                width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceStartDate | dateFormat }}
                </template>
              </el-table-column>

              <el-table-column
                prop="projectOutsourceAccomplishDate"
                align="center"
                label="完成时间"
                width="110"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectOutsourceAccomplishDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="appraiseGrade" align="center" label="评价等级" width="110">
              </el-table-column>
              <el-table-column prop="appraise" align="center" label="评价说明" min-width="220">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <el-dialog width="50%" title="增值税类型说明" append-to-body :visible.sync="vatTypesShow">
      <div class="imgDialog">
        <img :src="`${imgVatTypes}?${new Date().getTime()}`" alt="增值税类型说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getInputValue,
  delcommafy,
  numberToCurrencyNo,
  changeMoneyToChinese,
} from '@/util/jsencrypt'
import { initTreeData } from '@/util/common'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    ChoicePost: () => import('@/components/ChoicePost.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    CostPlan: () => import('@/views/project/CostPlan.vue'),
  },
  props: {
    ProjectEditId: {
      type: String,
      default: '',
    },
    business: {
      type: Object,
      default: function () {
        return {}
      },
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    StautsType: {
      type: Array,
      default: function () {
        return []
      },
    },
    ProjectType: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      disabled: true,
      loading: false,
      deptData: [],
      postData: [],
      configure: {
        request: this.$api.user.listStaff,
        type: 'radio',
      },
      newArr: [],
      form: {
        id: null,
        checkOpinion: null,
      },
      projectSource: [],
      Project_Dept: [],
      AmountInWords: {
        predictContractAmount: null,
        projectBudget: null,
        actualContractAmount: null,
        predictExteriorBusinessCosts: null,
        predictDisposableProjectExpense: null,
        predictTaxesFee: null,
        tenderFee: null,
        exteriorBusinessCosts: null,
        actualDisposableProjectExpense: null,
        taxesFee: null,
        tenderFee: null,
        // collaborationCosts: null,
        actualAmount: null,
      },

      zzsList: [],
      projectManagerList: [],
      projectManagerDeptId: null,
      projectManagerDeptList: [],
      projectManagerFnList: [],
      projectManager: {
        id: null,
        userName: null,
      },

      deptCompetentManagerList: [],
      deptCompetentManagerDeptId: null,
      deptCompetentManager: {
        id: null,
        userName: null,
      },

      deputyGeneralManagerList: [],
      deputyGeneralManagerDeptId: null,
      deputyGeneralId: null,
      deputyGeneralName: null,
      vatTypesShow: false,
      imgVatTypes: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/税率表.png',
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      originalForm: state => state.project.originalForm,
    }),
    Sj_actualAdditionalTax() {
      //实际附加税
      let num = 0
      num = (this.Sj_configcomp * 0.12).toFixed(2)
      return Number(num)
    },
    Sj_actualStampTax() {
      //实际印花税
      let num = 0
      num = (delcommafy(this.projectInformation.actualAmount) * 0.0003).toFixed(2)
      return Number(num)
    },
    Sj_configcomp() {
      //实际增值税
      let num = 0
      num = (
        (((Number(delcommafy(this.projectInformation.actualAmount)) * 10000) /
          (1000000 + this.projectInformation.vatRate * 10000)) *
          (this.projectInformation.vatRate * 10000)) /
        10000
      ).toFixed(2)

      return Number(num)
    },
    SJ_taxes() {
      //实际总税费
      let num = 0
      num = this.Sj_configcomp + this.Sj_actualAdditionalTax + this.Sj_actualStampTax
      // return (this.projectInformation.taxesFee = num);
      return Number(num)
    },
    YJ_taxes() {
      //预计总税费
      let num = 0
      num = this.j_configcomp + this.j_actualAdditionalTax + this.j_actualStampTax

      return Number(num)
      // return (this.projectInformation.predictTaxesFee = num);
    },
  },
  watch: {
    originalForm: {
      //immediate:true,//初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        var arr = []
        try {
          if (newVal.projectInformation) {
            newVal.projectInformation.projectDeptList.some(r => {
              arr.push(r.deptId)
            })
          }
        } catch (e) {
          console.log(e)
        }
      },
    },
    ProjectEditId: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.init(newVal)
      },
    },
    projectInformation: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.newArr = newVal.projectDeptList
      },
    },
    'projectInformation.projectStartName': {
      immediate: true, //初始化立即执行
      handler: function (newVal, oldVal) {
        if (newVal == '' || newVal == null) {
          if (this.originalForm.businessManage) {
            this.$api.businessManage
              .getBusinessManage(this.originalForm.businessManage.id)
              .then(res => {
                this.projectInformation.projectStartName = res.data.businessManage.initiatorName
              })
              .catch(err => {})
          }
        }
      },
    },

    SJ_taxes: {
      immediate: true, //初始化立即执行
      // deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.AmountInWords.taxesFee = changeMoneyToChinese(newVal)
      },
    },
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  created() {
    Promise.all([this.$api.sysDept.listDept(), this.$api.sysConfig.listPost()])
      .then(r => {
        this.deptData = r[0].data
        this.postData = r[1].data
      })
      .catch(err => {})
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.projectSource = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('Project_Dept', true)
      .then(res => {
        this.Project_Dept = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.vat
      .getVatTypeList()
      .then(res => {
        this.zzsList = res?.data?.records
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.project.listDepartmentStaff({ pageSize: 1000000 }).then(res => {
      if (res.data.records) {
        this.deptCompetentManagerList = res.data.records
      }
    })
    this.$api.project.listDepartmentHeadStaff({ pageSize: 1000000 }).then(res => {
      if (res.data.records) {
        this.deputyGeneralManagerList = res.data.records
      }
    })
  },
  mounted() {},
  methods: {
    init(id) {
      this.loading = true
      if (id) {
        this.$api.project
          .selectDetailById(id)
          .then(res => {
            this.$store.commit({
              type: 'project/SET_ORIGINALFORM',
              originalForm: res.data.deepClone(),
            })
            this.$store.commit({
              type: 'project/SET_PROJECTINFORMATION',
              projectInformation: res.data.projectInformation,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTCOSTPLANLIST',
              projectCostPlanList: res.data.projectCostPlanList,
            })
            if (res.data.projectInformation != null) {
              this.$store.commit({
                type: 'project/SET_PROJECTCOSTPLANFILELIST',
                projectCostPlanFileList: res.data.projectInformation.projectCostPlanFileList,
              })
              var Obj = res.data.projectInformation.deepClone()
              if (this.projectInformation.projectDeptList.length > 0) {
                this.projectInformation.projectDeptList.forEach(v => {
                  if (v.predictAmount) {
                    v.predictAmount = Number(v.predictAmount).toLocaleString()
                  }
                })
              }
              if (this.projectInformation.projectOutsourceList.length > 0) {
                this.projectInformation.projectOutsourceList.forEach(v => {
                  if (v.outsourceCost) {
                    v.outsourceCost = Number(v.outsourceCost).toLocaleString()
                  }
                })
              }
              for (const key in this.AmountInWords) {
                for (const key1 in Obj) {
                  if (!Array.isArray(this.AmountInWords[key]) && !Array.isArray(Obj[key1])) {
                    if (key == key1) {
                      this.AmountInWords[key] = changeMoneyToChinese(Obj[key1])
                      this.projectInformation[key] = Number(Obj[key1]).toLocaleString()
                    }
                  }
                }
              }
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.fullScreen {
  .cost {
    padding: 20px 0;
    /deep/.el-timeline-item__timestamp {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
  .danwei {
    position: absolute;
    right: 2%;
  }
  /deep/#checkOpinion {
    .el-form-item__label {
      font-size: 16px !important;
      color: red !important;
    }
  }
  .Taxes {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
  }
}
/deep/.vatLabelClassName {
  width: 12.5%;
}
/deep/.taxLabelClassName {
  width: 8%;
}
</style>
